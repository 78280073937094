@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-Light.otf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-LightItalic.otf");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-Regular.otf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-RegularItalic.otf");
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-Medium.otf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-MediumItalic.otf");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-Bold.otf");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/fonts/Lemon Milk/LEMONMILK-BoldItalic.otf");
  font-weight: bold;
  font-display: swap;
  font-style: italic;
}

#__next{
  white-space: pre-line;

}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

h1 , h2 , h3 , h4 , h5 , h6 , button {
  font-family: 'Lemon Milk', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

#toastportal{
  z-index: 3;
  position: fixed;
}

.question {
  color: #fff !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* transition: all 0.4s ease-in-out; */
}

.blog-pre {
  position: relative;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: #13161D;
  }
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #161a25;
  height: 10px;
}

*::-webkit-scrollbar {
  border-radius: 0px;
  width: 4px;
  background-color: #161a25;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  width: 4px;
  background-color: #303444;
  height: 10px;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


